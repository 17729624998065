<template>
    <nav>
        <div class="left">Sam' <div class="sp">Space</div>
        </div>
        <div class="right" >
            <ul :class="{ active: isActive }">
                <li><a href="/">Home</a></li>
                <li><a href="/">About</a></li>
                <li><a href="/">Services</a></li>
                <li><a href="/">Projects</a></li>
                <li><a href="/">Contact Me</a></li>
            </ul>
        </div>
        <div class="hamburger"  @click="toggleMenu">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
        </div>


    </nav>
</template>
  
<script>
export default {
    name: 'NavbarComp',
    data() {
    return {
      isActive: false
    };
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
    }
  }
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.left {
    background: rgb(11, 11, 61);
    font-size: 1.7rem;
    display: flex;
    justify-content: space-between;
}

.sp {
    color: aqua;
    background: rgb(11, 11, 61);
}

nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    height: 80px;
    background: rgb(11, 11, 61);
    color: aliceblue;

}

nav ul {
    display: flex;
    justify-content: center;
    background: rgb(11, 11, 61)
}

nav ul li {
    list-style: none;
    margin: 0 40px;
    /* color: azure; */

}

nav ul li a {
    text-decoration: none;
    color: aliceblue;
}

nav ul li a:hover {
    text-decoration: none;
    color: rgb(87, 123, 154);
}

a {
    background: rgb(11, 11, 61)
}

li {
    background: rgb(11, 11, 61)
}

.hamburger{
    display: none;
    /* flex-direction: column; */
    cursor: pointer;
}

.bar{
    display: block;
    width:25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: aliceblue;
}


@media(max-width: 820px) {
     .hamburger{
        display: block;
        background:rgb(11, 11, 61);
     }

     .hamburger.active .bar:nth-child(2){
        opacity: 0;
     }
     .hamburger.active .bar:nth-child(1){
        transform: translate(8px) rotate(45deg);
     }

     .hamburger.active .bar:nth-child(3){
        transform: translate(-8px) rotate(-45deg);
     }

     nav ul{
        
        position: fixed;
        left: -100%;
        top: 70px;
        gap: 0;
        flex-direction: column;
        background:rgb(11, 11, 61);
        width: 100%;
        text-align: center;
        transition: 0,3s;

     }

     nav ul li{
        margin: 16px 0;
     }

     nav ul.active{
        left:0;
     }

}
</style>