<template>
  <Navbar/>
  <BodyComp/>
</template>

<script>
import Navbar from './components/Navbar.vue';
import BodyComp from './components/Body.vue'


export default {
  name: 'App',
  components: {
    Navbar,
    BodyComp
  }
}
</script>

<style>

*{
  margin: 0;
  padding: 0;
  background: rgb(0, 0, 47);
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Rubik+Bubbles&display=swap');

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  margin-top: 60px; */
  color: whitesmoke;
}
</style>
